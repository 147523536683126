import React, { useState } from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import PaginationElement from '../Components/PaginationElement';
import TransactionTableHeader from './TransactionTableHeader';
import TransactionFilterElement from './TransactionFilterElement';
import TransactionModal from './TransactionModalElement';
import TransactionTableBody from './TransactionTableBody';
import headers from './TransactionsHeaderData.json';

const TransactionTableElement = ({ transactionsResponse, setSearchQueryValue }) => {
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [transactionSearchQuery, setTransactionSearchQuery] = useState({ reportType: 'transaction' });

  const goToPage = (pageObj) => {
    setSearchQueryValue({ ...transactionSearchQuery, page: pageObj.page });
    setTransactionSearchQuery((prevQuery) => ({
      ...prevQuery,
      page: pageObj.page,
    }));
  };

  const sort = async (order, orderBy) => {
    const orderData = { order, order_by: orderBy };
    setSearchQueryValue({ ...transactionSearchQuery, ...orderData, page: 1 });
    setTransactionSearchQuery((prevQuery) => ({
      ...prevQuery,
      ...orderData,
      page: 1,
    }));
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const showTransactionModal = async (transaction) => {
    await setSelectedTransaction(transaction);
    setShowModal(true);
  };

  const setTransactionQuery = async (query) => {
    setSearchQueryValue({ ...transactionSearchQuery, ...query, page: 1 });
    await setTransactionSearchQuery((prevQuery) => ({
      ...prevQuery,
      ...query,
      page: 1,
    }));
  };

  return (
    <div className="top-buffer30">
      <TransactionModal
        closeModal={closeModal}
        showModal={showModal}
        selectedTransaction={selectedTransaction}
      />
      <TransactionFilterElement setSearchQueryValue={setTransactionQuery} />
      <table className="table table-striped">
        <TransactionTableHeader sort={sort} headers={headers} />
        <TransactionTableBody
          transactionsResponse={transactionsResponse}
          showTransactionModal={showTransactionModal}
        />
      </table>
      <PaginationElement
        transactionsResponse={transactionsResponse}
        goToPage={goToPage}
        getTransactions={goToPage}
      />
    </div>
  );
};

export default TransactionTableElement;
