import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TransactionTableHeader from '../../Transactions/TransactionTableHeader';
import headers from './TransactionGroupBouncedReportHeaderData.json';
import getAxiosClient from '../../Utilities/get-axios-client';
import PaginationElement from '../../Components/PaginationElement';
import GroupReportChartOptions from '../GroupReportChartOptions.json';
import TransactionGroupBouncedReportTableBody from './TransactionGroupBouncedReportTableBody';
import BouncedGroupReportHeaderStats from './BouncedGroupReportHeaderStats';
import { defaultSearchQuery, getGroupStatsQuery } from '../TransactionGroupReportHandler';
import TransactionGroupReportElement from '../TransactionGroupReportElement';
import { reportStartDate, reportEndDate } from '../../Utilities/date-format';
import TextSearch from '../../Components/TextSearch';

class TransactionGroupBouncedReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionResponse: {},
      chartOptions: GroupReportChartOptions,
      searchQuery: { report_type: 'bounced', ...defaultSearchQuery() },
      dateRange: {},
    };
    this.groupElementRef = React.createRef();
  }

  async componentDidMount() {
    const dateRange = this.groupElementRef.current.dateValue;
    this.updateDateRange(dateRange);
    await this.getGroupStats(
      getGroupStatsQuery(
        'bounced',
        reportStartDate(dateRange.start),
        reportEndDate(dateRange.end),
      ),
    );
  }

  async getGroupStats(groupStatsQuery) {
    try {
      const response = await getAxiosClient()
        .post('v1/transaction-groups/reports', { ...groupStatsQuery });
      this.setState({
        transactionResponse: response.data.transactions,
      });
      const days = response.data.dailyStats.map((dailyStat) => dailyStat.tstat_day);
      const bouncedHard = response.data.dailyStats.map((dailyStat) => +dailyStat.bounces_hard);
      const bouncedSoft = response.data.dailyStats.map((dailyStat) => +dailyStat.bounces_soft);
      await this.setState((previousState) => ({
        chartOptions: {
          ...previousState.chartOptions,
          xAxis: {
            categories: days,
          },
          series: [{
            name: 'Hard Bounces',
            data: bouncedHard,
            color: '#ff7800',
          }, {
            name: 'Soft Bounces',
            data: bouncedSoft,
            color: '#ffc700',
          }],
        },
      }));
    } catch (e) {
      // eslint-disable-next-line
    }
  }

  async getTransactions(searchQuery) {
    const { dateRange } = this.state;
    const startDate = reportStartDate(dateRange.start);
    const endDate = reportEndDate(dateRange.end);
    const searchQueryUpdated = {
      ...searchQuery,
      start_date: startDate,
      end_date: endDate,
    };

    const response = await getAxiosClient().post(
      'v1/transaction/search',
      searchQueryUpdated,
      { withCredentials: true },
    );
    this.setState({ transactionResponse: response?.data ?? null });
  }

  setCurrentDateRangeValue = async (dateRange) => {
    this.updateDateRange(dateRange);
    await this.getGroupStats(
      getGroupStatsQuery('bounced', reportStartDate(dateRange.start), reportEndDate(dateRange.end)),
    );
  };

  sort = async (order, orderBy) => {
    const { searchQuery } = this.state;
    const updatedSearchQuery = {
      ...searchQuery,
      order,
      order_by: orderBy,
      page: 1,
    };
    await this.setState({ searchQuery: updatedSearchQuery });
    await this.getTransactions(updatedSearchQuery);
  };

  setSearchTextValue = async (transactionSearchString, dateRange) => {
    const { searchQuery } = this.state;
    const updatedSearchQuery = {
      ...searchQuery,
      transaction_search_string: transactionSearchString,
      page: 1,
    };
    await this.setState({ searchQuery: updatedSearchQuery, dateRange });
    await this.getTransactions(updatedSearchQuery);
  };

  updateDateRange(dateRange) {
    this.setState((prevState) => ({
      ...prevState,
      dateRange,
    }));
  }

  render() {
    const {
      transactionResponse,
      chartOptions,
      dateRange,
      searchQuery,
    } = this.state;
    const transactionsData = transactionResponse?.data ?? [];
    return (
      <TransactionGroupReportElement
        ref={this.groupElementRef}
        setCurrentDateRangeValue={this.setCurrentDateRangeValue}
        reportType="bounced"
        searchQuery={searchQuery}
      >
        <BouncedGroupReportHeaderStats dateRange={dateRange} />
        <div className="padding30 border-light top-buffer30">
          <h4 className="bottom-buffer30 top-buffer0">Activity</h4>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
          />
        </div>
        <div>
          <div className="row top-buffer30">
            <div className="col-md-3">
              <TextSearch getTransactions={this.setSearchTextValue} dateRange={dateRange} />
            </div>
          </div>
          <div className="padding10 border-light top-buffer20">
            <table className="table table-striped">
              <TransactionTableHeader sort={this.sort} headers={headers} />
              <TransactionGroupBouncedReportTableBody transactionsData={transactionsData} />
            </table>
          </div>
          <PaginationElement
            transactionsResponse={transactionResponse}
            transactionSearchQuery={searchQuery}
            getTransactions={this.getTransactions}
            dateRange={dateRange}
          />
        </div>
      </TransactionGroupReportElement>
    );
  }
}

export default withRouter(TransactionGroupBouncedReport);
