import { Link } from 'react-router-dom';
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import getAxiosClient from '../Utilities/get-axios-client';
import { AuthenticationContext } from '../Auth/AuthenticationContext';

const navbar = () => {
  const [authentication] = useContext(AuthenticationContext);
  const { enterprise, user } = authentication;
  const canSend = user?.user_can_send_transactions === 'yes' ?? true;
  const [navState, setNavState] = useState({
    isExpanded: false,
    className: 'nav-collapsed',
    title: 'Show Menu',
  });
  const [logoutWidth, setLogoutWidth] = useState(0);
  const navRef = useRef(null);

  const toggleExpandNav = () => {
    setNavState((prevState) => ({
      isExpanded: !prevState.isExpanded,
      className: !prevState.isExpanded ? 'nav-expanded' : 'nav-collapsed',
      title: !prevState.isExpanded ? 'Hide Menu' : 'Show Menu',
    }));
  };

  // Check when navState classname changes to dynamically change logout icon width
  // due to logout UL being fixed position
  useEffect(() => {
    setTimeout(() => {
      if (navRef.current) {
        setLogoutWidth(navRef?.current?.offsetWidth);
      }
    }, 500);
  }, [navState.isExpanded]);

  const logout = async () => {
    try {
      await getAxiosClient().post('/v1/logout');
      window.location.href = enterprise.enterprise_pmailer_url;
    } catch (e) {
      // eslint-disable-next-line
    }
  };

  const activeMenu = (() => {
    if (window.location.pathname === '/') {
      return 'dashboard';
    }
    if (window.location.pathname === '/settings') {
      return 'settings';
    }
    if ((window.location.pathname.split('/').includes('templates'))) {
      return 'templates';
    }
    if ((window.location.pathname.split('/').includes('online-attachments'))) {
      return 'online-attachments';
    }
    if (window.location.pathname === '/suppression-list') {
      return 'suppression-list';
    }
    if (window.location.pathname === '/webhook-logs') {
      return 'webhook-logs';
    }
    return 'reports';
  })();

  return (
    <nav ref={navRef} className={`navbar-left ${navState.className}`}>
      <ul className="nav">
        <li className="dashboard">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            role="button"
            tabIndex={0}
            aria-label="Previous"
            data-toggle="tooltip"
            data-placement="right"
            title={navState.title}
            data-original-title={navState.title}
            className="expand-menu"
            onKeyDown={toggleExpandNav}
            onClick={toggleExpandNav}
          >
            <i className="seve-icon-angle-right" />
          </a>
        </li>
        <li className={`dashboard ${activeMenu === 'dashboard' ? 'active' : ''}`}>
          <Link to="/">
            <div className="display-inline-flex">
              <i className="seve-icon-scale" />
              <p className="nav-menu-text">Dashboard</p>
            </div>

          </Link>
        </li>
        <li className={`transactional-groups dashboard ${activeMenu === 'reports' ? 'active' : ''}`}>
          <Link to="/groups">
            <div className="display-inline-flex">
              <i className="seve-icon-business-chart-1" />
              <p className="nav-menu-text">Transactional Groups Reports</p>
            </div>
          </Link>
        </li>
        {canSend && (
          <>
            <li className={`transactional-groups dashboard ${activeMenu === 'suppression-list' ? 'active' : ''}`}>
              <Link to="/suppression-list">
                <div className="display-inline-flex">
                  <i className="seve-icon-user-block-2" />
                  <p className="nav-menu-text">Suppression List</p>
                </div>
              </Link>
            </li>
            <li className={`templates dashboard ${activeMenu === 'templates' ? 'active' : ''}`}>
              <Link to="/templates">
                <div className="display-inline-flex">
                  <i className="seve-icon-arrange-4-1" />
                  <p className="nav-menu-text">Templates</p>
                </div>
              </Link>
            </li>
          </>
        )}
        <li className={`webhook-logs dashboard ${activeMenu === 'webhook-logs' ? 'active' : ''}`}>
          <Link to="/webhook-logs">
            <div className="display-inline-flex">
              <i className="seve-icon-webhook" />
              <p className="nav-menu-text">Webhook Logs</p>
            </div>
          </Link>
        </li>
      </ul>
      <ul className="nav stick-bottom">
        {canSend && (
          <li className={`${activeMenu === 'settings' ? 'active' : ''}`}>
            <Link
              to="/settings"
              data-toggle="tooltip"
              data-placement="right"
              title=""
              data-original-title="Settings"
            >
              <div className="display-inline-flex">
                <i className="seve-icon-setting-gear" />
                <p className="nav-menu-text">Settings</p>
              </div>
            </Link>
          </li>
        )}
        <li className="nav-logout" style={{ width: `${logoutWidth}px` }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            role="button"
            tabIndex={0}
            aria-label="Previous"
            onClick={logout}
            onKeyDown={logout}
            data-toggle="tooltip"
            data-placement="right"
            title=""
            data-original-title="Logout"
          >
            <div className="display-inline-flex">
              <i className="seve-icon-power-5" />
              <p className="nav-menu-text" style={{ marginTop: '0.7rem' }}>Logout</p>
            </div>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default navbar;
