import React, { useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const TextSearch = ({
  getTransactions,
  placeholder = 'Search by Recipient Email or Transaction ID',
  dateRange,
}) => {
  const searchRef = useRef();
  const debouncedSearch = useDebouncedCallback(
    async () => {
      await getTransactions(searchRef.current.value, dateRange);
    },
    1000,
  );
  return (
    <div className="input-group">
      <input
        className="form-control"
        placeholder={placeholder}
        type="text"
        size="40"
        name="search"
        id="search"
        ref={searchRef}
        onChange={debouncedSearch}
      />
      <span className="input-group-addon">
        <i className="icon seve-icon-search-find text-white" />
      </span>
    </div>
  );
};

export default TextSearch;
