const PaginationElement = ({
  transactionsResponse,
  transactionSearchQuery,
  getTransactions,
  dateRange,
}) => {
  const go = async (page) => {
    const pageDate = { page };
    const newSearchQuery = {
      ...transactionSearchQuery,
      ...pageDate,
      start_date: dateRange?.start ?? 0,
      end_date: dateRange?.end ?? 0,
    };
    await getTransactions(newSearchQuery);
  };

  if (!transactionsResponse || !('meta' in transactionsResponse)) {
    return <></>;
  }

  const currentPageStartRecord = (
    ((transactionsResponse.meta.current_page - 1) * transactionsResponse.meta.per_page) + 1
  );
  const currentPageTotalRecord = (
    (
      (transactionsResponse.meta.current_page - 1) * transactionsResponse.meta.per_page
    ) + transactionsResponse.data.length
  );
  const totalRecords = transactionsResponse.meta.total;

  if (totalRecords === 0) {
    return <></>;
  }
  return (
    <div className="display-flex justify-content-end top-buffer20">
      {/* eslint-disable */}
      <p className="margin0 padding7">
        {`Displaying ${currentPageStartRecord}-${currentPageTotalRecord} of ${totalRecords}`}
      </p>
      <nav aria-label="...">
        <ul className="pagination margin0">
          <li className={1 === +transactionsResponse.meta.current_page ? 'disabled' : ''}>
            <a role="button" onClick={() => go(1)} aria-label="Previous">
              <span aria-hidden="true"><i className="seve-icon-angle-double-left" /></span>
            </a>
          </li>
          <li className={1 === +transactionsResponse.meta.current_page ? 'disabled' : ''}>
            <a role="button" onClick={() => go(transactionsResponse.meta.current_page - 1)} aria-label="Previous">
              <span aria-hidden="true"><i className="seve-icon-angle-left" /></span>
            </a>
          </li>
          <li className={(+transactionsResponse.meta.current_page === +transactionsResponse.meta.last_page || +transactionsResponse.meta.last_page === 0) ? 'disabled' : ''}>
            <a role="button" onClick={() => go(+transactionsResponse.meta.current_page + 1)} aria-label="Next">
              <span aria-hidden="true"><i className="seve-icon-angle-right" /></span>
            </a>
          </li>
          <li className={(+transactionsResponse.meta.current_page === +transactionsResponse.meta.last_page || +transactionsResponse.meta.last_page === 0) ? 'disabled' : ''}>
            <a role="button" onClick={() => go(transactionsResponse.meta.last_page)} aria-label="Next">
              <span aria-hidden="true"><i className="seve-icon-angle-double-right" /></span>
            </a>
          </li>
        </ul>
      </nav>
      {/* eslint-enable */}
    </div>
  );
};

export default PaginationElement;
