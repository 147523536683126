import React from 'react';
import moment from 'moment';
import Header from '../Layout/Header';
import getAxiosClient from '../Utilities/get-axios-client';
import TemplateListingOverview from './Listing/TemplateListingOverview';
import TextSearchElement from '../Components/TextSearchElement';
import PaginationElement from '../Components/PaginationElement';
import Alert from '../Utilities/Alert';
import ListingHeader from '../Components/Listing/ListingHeader';

class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templateName: '',
      orderBy: 'DESC',
      page: 1,
      isLoading: false,
      showDeleteAlert: false,
      templateResponse: {},
      selectedTemplate: {},
      checkedTemplateId: '',
    };
  }

  async componentDidMount() {
    document.title = 'Templates';
    const { templateName } = this.state;
    await this.get(templateName);
  }

  get = async (templateName, orderBy = 'DESC', page = 1) => {
    try {
      this.setState({ isLoading: true });
      const response = await getAxiosClient().post(
        '/v1/templates/search',
        { template_name: templateName, sort: `${(orderBy === 'DESC') ? '' : '-'}description`, page },
      );
      this.setState({
        selectedTemplate: (response?.data?.data ?? []).length > 0 ? response.data.data[0] : {},
        templateResponse: response.data,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  sort = async () => {
    const { orderBy, templateName, page } = this.state;
    const newOrderBy = (orderBy === 'DESC') ? 'ASC' : 'DESC';
    this.setState({ orderBy: newOrderBy });
    await this.get(templateName, newOrderBy, page);
  };

  goToPage = async (page) => {
    this.setState({ page });
    const { orderBy, templateName } = this.state;
    await this.get(templateName, orderBy, page);
  };

  searchTemplate = async (templateName) => {
    this.setState({ templateName });
    const { orderBy } = this.state;
    await this.get(templateName, orderBy, 1);
  };

  setSelectedTemplate = (selectedTemplate) => this.setState({ selectedTemplate });

  setCheckedTemplateId = (newCheckedTemplateId) => {
    const { checkedTemplateId } = this.state;
    const newId = (newCheckedTemplateId === checkedTemplateId) ? '' : newCheckedTemplateId;
    this.setState({ checkedTemplateId: newId });
  };

  deleteClick = async () => {
    const { checkedTemplateId } = this.state;
    if (checkedTemplateId !== '') {
      try {
        this.setState({ isLoading: true });
        await getAxiosClient().delete(`/v1/templates/${checkedTemplateId}`);
        await this.setState({ showDeleteAlert: true });
        this.setState({ isLoading: false });
        await this.get('');
      } catch (error) {
        this.setState({ isLoading: false });
      }
    }
  };

  render() {
    const {
      templateName, templateResponse, selectedTemplate, orderBy, isLoading, checkedTemplateId,
      showDeleteAlert,
    } = this.state;
    const templates = templateResponse?.data ?? [];
    return (
      <>
        <Alert message="Template deleted successfully" type="success" showAlert={showDeleteAlert} />
        <Header>Templates</Header>
        <div className="">
          <div className="row">
            <div className="col-md-6 border-right-dark min-container-height d-flex justify-content-between flex-column">
              <div>
                <div className="row pad-top30 pad-left30 pad-bottom5">
                  <div className="col-md-6">
                    <ListingHeader createText="Create new template" createLink="/templates/create" deleteClick={this.deleteClick} />
                  </div>
                  <div className="col-md-6">
                    <TextSearchElement
                      dateRange="date"
                      transactionSearchQuery={templateName}
                      getTransactions={this.searchTemplate}
                      placeholder="Search templates"
                    />
                  </div>
                </div>
                <div className="pad-left30 pad-top10">
                  <table className={`table table-hover ${isLoading && 'disabled'}`}>
                    <thead>
                      <tr className="row" role="button">
                        <th className="col-sm-1">&nbsp;</th>
                        <th className="col-sm-8" onClick={this.sort}>
                          Name
                          <i className={`left-buffer5 seve-icon-chevron-${orderBy === 'DESC' ? 'down' : 'up'} icon-s-small`} />
                        </th>
                        <th className="col-sm-3">Last Published</th>
                      </tr>
                    </thead>
                    <tbody>
                      {templates.length > 0
                        ? (
                          templates.map((template) => (
                            <tr
                              className={`row ${(template.id === selectedTemplate.id) ? 'active' : ''}`}
                              role="button"
                              onClick={() => this.setSelectedTemplate(template)}
                              key={template.id}
                            >
                              <td className="col-sm-1">
                                <input
                                  type="checkbox"
                                  role="button"
                                  checked={checkedTemplateId === template.id}
                                  onChange={() => this.setCheckedTemplateId(template.id)}
                                />
                              </td>
                              <td className="col-sm-8">
                                <h6 className="top-buffer0 bottom-buffer0 font-weight-400">
                                  {template.name}
                                  {template.status === 'draft' && <span className="left-buffer10 label label-warning text-uppercase">Draft</span>}
                                </h6>
                                <small className="height-20">
                                  &nbsp;
                                  {template.description}
                                </small>
                              </td>
                              <td className="col-sm-3">{moment(template.updated_timestamp).format('d MMM YYYY - HH:mm')}</td>
                            </tr>
                          ))
                        )
                        : <tr><td colSpan="4">No templates found</td></tr>}
                    </tbody>
                  </table>
                </div>
              </div>
              {templates.length > 0
                ? (
                  <PaginationElement
                    transactionsResponse={templateResponse}
                    transactionSearchQuery={templateName}
                    getTransactions={this.goToPage}
                    dateRange="date"
                  />
                )
                : ''}
            </div>
            <div className="col-md-6 bg-gray-lightest min-container-height">
              <div className="padding30">
                <TemplateListingOverview template={selectedTemplate} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Templates;
