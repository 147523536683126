import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TransactionTableHeader from '../../Transactions/TransactionTableHeader';
import headers from './TransactionGroupClicksReportHeaderData.json';
import linkHeaders from './TransactionGroupLinksReportHeaderData.json';
import getAxiosClient from '../../Utilities/get-axios-client';
import GroupReportChartOptions from '../GroupReportChartOptions.json';
import ClicksGroupReportHeaderStats from './ClicksGroupReportHeaderStats';
import { defaultSearchQuery, getGroupStatsQuery } from '../TransactionGroupReportHandler';
import activateTooltip from '../../Utilities/activate-tooltip';
import TransactionGroupClicksReportTableBody from './TransactionGroupClicksReportTableBody';
import Tabs from '../../Utilities/Tabs';
import TransactionGroupLinksReportTableBody from './TransactionGroupLinksReportTableBody';
import PaginationElement from '../../Components/PaginationElement';
import TransactionGroupReportElement from '../TransactionGroupReportElement';
import { reportStartDate, reportEndDate } from '../../Utilities/date-format';
import TextSearch from '../../Components/TextSearch';

class TransactionGroupClicksReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionResponse: {},
      linksResponse: {},
      chartOptions: GroupReportChartOptions,
      searchQuery: { report_type: 'clicks', ...defaultSearchQuery() },
      dateRange: {},
    };
    this.groupElementRef = React.createRef();
  }

  async componentDidMount() {
    const dateRange = this.groupElementRef.current.dateValue;
    this.updateDateRange(dateRange);
    activateTooltip();
    const groupStatsQuery = getGroupStatsQuery(
      'clicks',
      reportStartDate(dateRange.start),
      reportEndDate(dateRange.end),
    );
    await this.getGroupStats(
      getGroupStatsQuery(
        'clicks',
        reportStartDate(dateRange.start),
        reportEndDate(dateRange.end),
      ),
    );
    const response = await getAxiosClient().post('v1/links', { ...groupStatsQuery });
    this.setState({
      linksResponse: response.data.links,
    });
  }

  async getGroupStats(groupStatsQuery) {
    try {
      const response = await getAxiosClient()
        .post('v1/transaction-groups/reports', { ...groupStatsQuery });
      this.setState({
        transactionResponse: response.data.transactions,
      });
      const days = response.data.dailyStats.map((dailyStat) => dailyStat.tstat_day);
      const uniqueClicks = response.data.dailyStats.map((dailyStat) => +dailyStat.unique_clicks);
      const totalClicks = response.data.dailyStats.map((dailyStat) => +dailyStat.link_clicks);
      await this.setState((previousState) => ({
        chartOptions: {
          ...previousState.chartOptions,
          xAxis: {
            categories: days,
          },
          series: [{
            name: 'Unique Clicks',
            data: uniqueClicks,
            color: '#3b5998',
          }, {
            name: 'Total Clicks',
            data: totalClicks,
            color: '#1abc9c',
          }],
        },
      }));
    } catch (e) {
      // eslint-disable-next-line
    }
  }

  getTransactions = async (searchQuery) => {
    const { dateRange } = this.state;
    const startDate = reportStartDate(dateRange.start);
    const endDate = reportEndDate(dateRange.end);
    const searchQueryUpdated = {
      ...searchQuery,
      start_date: startDate,
      end_date: endDate,
    };

    const response = await getAxiosClient().post(
      'v1/transaction/search',
      searchQueryUpdated,
      { withCredentials: true },
    );
    this.setState({ transactionResponse: response?.data ?? null });
  };

  getLinks = async (searchQuery) => {
    const params = new URLSearchParams(searchQuery);
    const response = await getAxiosClient().get(
      `v1/links${params.toString()}`,
      { withCredentials: true },
    );
    this.setState({ linksResponse: response?.data?.links ?? null });
  };

  setCurrentDateRangeValue = async (dateRange) => {
    this.updateDateRange(dateRange);
    await this.getGroupStats(
      getGroupStatsQuery('clicks', reportStartDate(dateRange.start), reportEndDate(dateRange.end)),
    );
  };

  sort = async (order, orderBy) => {
    const { searchQuery } = this.state;
    const updatedSearchQuery = {
      ...searchQuery,
      order,
      order_by: orderBy,
      page: 1,
    };
    await this.setState({ searchQuery: updatedSearchQuery });
    await this.getTransactions(updatedSearchQuery);
  };

  sortLinks = async (order, orderBy) => {
    const { searchQuery } = this.state;
    const updatedSearchQuery = {
      ...searchQuery,
      order,
      order_by: orderBy,
      page: 1,
    };
    await this.setState({ searchQuery: updatedSearchQuery });
    await this.getLinks(updatedSearchQuery);
  };

  setSearchTextValue = async (transactionSearchString) => {
    const { searchQuery } = this.state;
    const updatedSearchQuery = {
      ...searchQuery,
      transaction_search_string: transactionSearchString,
      page: 1,
    };
    await this.setState({ searchQuery: updatedSearchQuery });
    await this.getTransactions(updatedSearchQuery);
  };

  setLinkSearchTextValue = async (linkSearch, dateRange) => {
    const { searchQuery } = this.state;
    const updatedSearchQuery = { ...searchQuery, searchQuery: linkSearch, page: 1 };
    await this.setState({ searchQuery: updatedSearchQuery, dateRange });
    await this.getLinks(updatedSearchQuery);
  };

  updateDateRange(dateRange) {
    this.setState((prevState) => ({
      ...prevState,
      dateRange,
    }));
  }

  render() {
    const tabOptions = [
      { name: 'Recipients', targetValue: '#recipients', isActive: true },
      { name: 'Links', targetValue: '#links', isActive: false },
    ];

    const {
      transactionResponse,
      linksResponse,
      chartOptions,
      dateRange,
      searchQuery,
    } = this.state;
    const transactionsData = transactionResponse?.data ?? [];
    const linksData = linksResponse?.data ?? [];
    return (
      <TransactionGroupReportElement
        ref={this.groupElementRef}
        setCurrentDateRangeValue={this.setCurrentDateRangeValue}
        reportType="clicks"
        searchQuery={searchQuery}
      >
        <ClicksGroupReportHeaderStats dateRange={dateRange} />
        <div className="padding30 border-light top-buffer30">
          <h4 className="bottom-buffer30 top-buffer0">Activity</h4>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
          />
        </div>
        <div>
          <div className="top-buffer20 bottom-buffer20">
            <Tabs tabOptions={tabOptions} />
          </div>
          <div className="tab-content padding0">
            <div id="recipients" className="tab-pane fade in active">
              <div className="row top-buffer30">
                <div className="col-md-3">
                  <TextSearch getTransactions={this.setSearchTextValue} />
                </div>
              </div>
              <div className="padding10 border-light top-buffer20">
                <table className="table table-striped">
                  <TransactionTableHeader sort={this.sort} headers={headers} />
                  <TransactionGroupClicksReportTableBody
                    transactionsData={transactionsData}
                  />
                </table>
              </div>
              <PaginationElement
                transactionsResponse={transactionResponse}
                transactionSearchQuery={searchQuery}
                getTransactions={this.getTransactions}
                dateRange={dateRange}
              />
            </div>
            <div id="links" className="tab-pane fade">
              <div className="row top-buffer30">
                <div className="col-md-3">
                  <TextSearch
                    getTransactions={this.setLinkSearchTextValue}
                    placeholder="Search link"
                    dateRange={dateRange}
                  />
                </div>
              </div>
              <div className="padding10 border-light top-buffer20">
                <table className="table table-striped">
                  <TransactionTableHeader sort={this.sortLinks} headers={linkHeaders} />
                  <TransactionGroupLinksReportTableBody
                    linksData={linksData}
                  />
                </table>
              </div>
              <PaginationElement
                transactionsResponse={linksResponse}
                transactionSearchQuery={searchQuery}
                getTransactions={this.getTransactions}
                dateRange={dateRange}
              />
            </div>
          </div>
        </div>
      </TransactionGroupReportElement>
    );
  }
}

export default withRouter(TransactionGroupClicksReport);
