import React, { useState } from 'react';
import moment from 'moment';
import TextSearch from '../Components/TextSearch';
import TransactionDatePickerSearchElement from './TransactionDatePickerSearchElement';

const TransactionFilterElement = ({ setSearchQueryValue }) => {
  const [searchText, setSearchText] = useState('');
  const [currentMessageStatus, setCurrentMessageStatus] = useState('');

  const [currentDateRange, setCurrentDateRange] = useState({
    start: moment().subtract(29, 'days'),
    end: moment(),
  });

  const searchTransactions = (key, value) => {
    let transactionSearchString = searchText;
    let messageStatus = currentMessageStatus;
    let dateRange = currentDateRange;
    if (key === 'messageStatus') {
      setCurrentMessageStatus(value);
      messageStatus = value;
    }
    if (key === 'transactionSearchString') {
      transactionSearchString = value;
    }
    if (key === 'dateRange') {
      setCurrentMessageStatus(value);
      dateRange = value;
    }
    setSearchQueryValue({
      message_status: messageStatus,
      transaction_search_string: transactionSearchString,
      start_date: dateRange.start.unix(),
      end_date: dateRange.end.unix(),
    });
  };

  const setSearchTextValue = (value) => {
    searchTransactions('transactionSearchString', value);
    setSearchText(value);
  };

  const messageStatusValues = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Sent',
      value: 'sent',
    },
    {
      label: 'Failed',
      value: 'failed',
    },
    {
      label: 'Delivered',
      value: 'delivered',
    },
  ];

  const setCurrentDateRangeValue = (newDateRange) => {
    searchTransactions('dateRange', newDateRange);
    setCurrentDateRange(newDateRange);
  };

  return (
    <div className="row pad-top10 pad-bottom30">
      <div className="col-md-3">
        <TransactionDatePickerSearchElement setCurrentDateRangeValue={setCurrentDateRangeValue} />
      </div>
      <div className="col-md-3">
        <TextSearch placeholder="Search by recipient email or transaction ID" getTransactions={setSearchTextValue} />
      </div>
      <div className="col-md-3">
        {messageStatusValues.map((messageStatus) => (
          <button
            key={messageStatus.label}
            type="button"
            className={`btn right-buffer10 border-radius50 ${currentMessageStatus === messageStatus.value ? 'btn-primary' : 'btn-default'}`}
            onClick={() => searchTransactions('messageStatus', messageStatus.value)}
          >
            {messageStatus.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TransactionFilterElement;
